.table-header-cell-dark {
  background-color: #000534 !important;
  color: #fff !important;
}

.table-header-cell-white {
  background-color: #DDD !important;
  color: #303030 !important;
}

.table-body-cell-dark {
  background-color: #020845 !important;
  color: #c6c6c6 !important;
  border-bottom-color: "#5d5d5d";
}

.table-body-cell-white {
  background-color: #fff !important;
  color: "#303030" !important;
  border-bottom-color: "grey";
}

.table-body-cell-invalid {
  background-color: #E74C3C !important;
  color: "#303030" !important;
  border-bottom-color: "grey";
}

.view-menu{
  position: relative;
}

.view-menu::after{
  content: "";
  background: #FFFFFF20;
  position: absolute;
  top : 15%;
  Right: 0;
  height: 70%;
  width: 1px;
}

.main-accordion {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: transparent !important;
}

.accordion-summary-title {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  margin-top: 0 !important; 
  background-color: #0056d2 !important;
  padding: 0px 10px;
  color: #FFFFFF;
}

.accordion-summary-main-div {
  width: 100%;
  background-color: #0056d2;
  color: #FFFFFF;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.accordion-summary-main-div p {
  font-size: 16px;
  margin-top: 3px !important;
  margin-bottom: 0;
}

.edit-save-icon-btn {
  color: #FFFFFF;
  font-size: 16px !important;
  margin-top: -2px;
}

.emission-table-main-div {
  /* background-color: #202b60; */
  border-radius: 10px;
}

.emission-table-container {
  background-color: transparent !important;
  color: #FFFFFF;
  border-radius: 5px;
}

.accordion-parent-div {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 30px;
}

.refFileName-link-dark {
  white-space: nowrap;
  border-radius: 5px;
  cursor: pointer;
  color: #c6c6c6;
  border: 1px solid #5e5e5e;
  background: #182149;
  padding: 11px 5px;
  width: 123px;
}

.refFileName-link-white {
  white-space: nowrap;
  border-radius: 5px;
  cursor: pointer;
  color: #303030;
  border: 1px solid #c6c6c6;
  background: #FFFFFF;
  padding: 11px 5px;
  width: 123px;
}

.fileList .list{
  transition: 0.2s;
}

.fileList .list:hover{
  background-color: #00000010;
}

