body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #c6c6c6;
  background-repeat: no-repeat;
  background-attachment: fixed;
  
}

div{
  scrollbar-color: #c6c6c6 transparent !important;
  scrollbar-width: thin !important;
  scrollbar-arrow-color: transparent !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#StackBar {
  width: 500;
  height: 1000;
}

::-webkit-scrollbar {
  width: 17px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: rgb(36, 36, 36);  */
  background: transparent;
  border-radius: 10px;

}
 
/* Handle */
::-webkit-scrollbar-thumb:vertical {
  background: rgb(189, 188, 188, 0.7); /*older color : #888, old color: rgb(189, 188, 188)*/
  border-radius: 10px;
  background-clip: content-box;
  border: 5px solid transparent;
}

::-webkit-scrollbar-thumb:horizontal {
  background: rgb(189, 188, 188, 0.7); /*older color : #888, old color: rgb(189, 188, 188)*/
  border-radius: 10px;
  /* background-clip: content-box; */
  border: 5px solid transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  scrollbar-color: rgb(255,255,255,20%) transparent;
  border-radius: 10px;
}

.scroll{
  scrollbar-color: #555 transparent;
  scrollbar-width: thin !important;
}

/* scrollbar corner */
::-webkit-scrollbar-corner {
  display: none;
}

/* body scrollbar style */
.body-scroll{
  height: 100vh;
  overflow-y: scroll;
}
.body-scroll::-webkit-scrollbar-thumb {
  background: rgb(189, 188, 188, 0.8); 
  border-radius: 10px;
}
.body-scroll::-webkit-scrollbar {
  width: 10px;
  height: 4px;
}
.body-scroll::-webkit-scrollbar-track-piece:start{
  margin-top: 1.5px;
}
.body-scroll::-webkit-scrollbar-track-piece:end{
  margin-bottom: 1.5px;
}
/* end of body scrollbar style */

/* Chrome, Safari, Edge, Opera */
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.dark .buildingList:hover{
  background-color: #2D3C86 !important;
  color: #fff !important;
}

.light .buildingList:hover{
  background-color: #0056D2 !important;
  color: #fff !important;
}

.my-modal .modal-content{
  background-color: rgb(0 21 87);
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track, 
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after { 
  background: rgba(0, 0, 0, 0.5);
  padding: 0px !important;
  overflow: hidden !important;
}

.gm-style-iw-d {
  padding: 0px 0px 10px 0px !important;
  overflow: hidden !important;
}

.gm-ui-hover-effect {
  background: white !important;
    display: block !important;
    border: 0px !important;
    margin: 0px !important;
    padding: 0px !important;
    text-transform: none !important;
    appearance: none !important;
    position: absolute !important;
    cursor: pointer !important;
    user-select: none !important;
    top: 2px !important;
    right: 2px !important;
    width: 30px !important;
    /* color: white !important; */
    height: 30px !important;
    border-radius: 50px !important;
}

.gm-style-iw-tc {
  display: none !important;
}

.ForwardRef-iconButton-2 {
  color: white !important;
}

.selectedColor {
  color: #53f9ff;
  border-right: 2px solid;
}

.whiteColor {
  color: white;
}

#reactMde-noHeader .mde-header{
  display: none !important;
}

#reactMde-noHeader .react-mde{
  width: 800px !important;
  border: none;
}



#reactMde-noHeader .mde-preview-content{
  color: black !important;
  background-color: white !important;
  padding: 99px !important;
}