#analysisChart .c3 path,
#analysisChart .c3 line {
  fill: none;
  stroke: #969696;
  stroke-width: 0.25px;
}

#analysisChart circle {
  stroke: #0E9CFF;
  stroke-width: 1px;
  fill: white !important;
}

.dark-theme .c3 text {
  fill: #969696;
  font-size: 12px;
}

.white-theme .c3 text {
  fill: #414141;
  font-size: 12px;
}


.c3-region.region-dark-2 {
  fill: rgb(44, 33, 253);
}

.c3-region.region-dark-3 {
  fill: rgb(64, 130, 254);
}

.c3-region.region-light-2 {
  fill: rgb(44, 37, 37);
}

.c3-region.region-light-3 {
  fill: rgb(2, 29, 50);
}

.custom-tooltip {
  border: '1px solid red'
}

.c3-region text {
  fill-opacity: 1 !important;
}