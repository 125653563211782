.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.energy {
  font-size: 15px;
  font-weight: bold;
  color: #ff5c00;
}

.water {
  font-size: 15px;
  font-weight: bold;
  color: #2982ff;
}

.plastic {
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
}

.plastic_black {
  font-size: 15px;
  font-weight: bold;
  color: #000000;
}

.greenMaterial {
  font-size: 15px;
  font-weight: bold;
  color: #04c500;
}
.cardTitle {
  font-size: 15px;
  font-weight: bold;
  /* color: #ffffff; */
}
.progress-bar-marker {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 97%, #04c500 3%);
}

table thead > tr > th {
  vertical-align: bottom;
  border-bottom: none !important;
}

.cardStyle {
  border-top:1px solid #8080801c;
  border-left:1px solid #8080801c;
  box-shadow: 2px 4px 11px rgba(0, 0, 0, 0.25);
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 8;
  color: #c6c6c6;
  font-size: 12px;
}
.tableStyle {
  box-shadow: 2px 4px 11px rgba(0, 0, 0, 0.25);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 8;
  color: #c6c6c6;
}
.iconCardStyle {
  cursor: pointer;
  width: 100px;
  height: 100px;
  /* box-shadow: 2px 4px 11px rgba(0,0,0,0.3); */
  /* padding: 10px; */
  border-radius: 5px;
  /* margin-bottom: 8; */
  color: #c6c6c6;
  /* font-size: 12px; */
  background-color: #ffff;
}
.hover :hover {
  color: #2982ff;
}

.red-circle {
  color: #ff3400 !important;
}

.waterColor code {
  color: #2982ff !important;
  position: relative !important;
}
.energyColor code {
  color: #ca4a02 !important;
  position: relative !important;
}
.plasticColor code {
  color: #f6f6f6 !important;
  position: relative !important;
}
.greenColor code {
  color: #04c500 !important;
  position: relative !important;
}
.dark .modal-header {
  border-bottom: 1px solid rgb(255, 255, 255, 0.1) !important;
}
.light .modal-header {
  border-bottom: 1px solid rgb(0, 0, 0, 0.1) !important;
}
.dark .modal-footer {
  border-top: 1px solid rgb(255, 255, 255, 0.1) !important;
}
.light .modal-footer {
  border-bottom: 1px solid rgb(0, 0, 0, 0.1) !important;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: -webkit-box;
  width: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-pagination {
  bottom: 95%;
}

.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -5px;
}

.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal
  > span.swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  color: #a3a3a3;
  background-color: #a3a3a3;
  opacity: 0.4;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 15px;
  height: 15px;
  opacity: 1 !important;
}

.swiper-wrapper {
  padding: 10px 0px 25px 0px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user_management_btn:hover {
  background-color: #2d3c86 !important;
  color: #fff !important;
  /* box-shadow:rgb(0 0 0 / 30%) 2px 3px 10px; */
}

.user_management_light_btn:hover {
  background-color: #0056d2 !important;
  color: #fff !important;
}

.css-z7uhs0-MuiStepConnector-line {
  border-color: #2d3c86 !important;
  height: 13px !important;
}

.darkTheme .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  color: rgb(255 255 255 / 10%) !important;
}

.lightTheme .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  color: rgb(0 0 0 / 30%) !important;
}

/* .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  color: #b1b1b1 !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  color: #b1b1b1 !important;
} */

/* .lightTheme .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  color: #C6C6C6 !important;
} */

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #1976d2 !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #1976d2 !important;
}

/* breadcrumb divider style */
.breadcrumb-item + .breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, ">") !important;
}

/* mui table */
.MuiTablePagination-caption {
  /* margin-top: 2px !important; */
}
.MuiTableSortLabel-icon {
  color: #ffffff !important;
}
.MuiIconButton-root.Mui-disabled {
  color: #b1b1b1 !important;
}
.MuiTablePagination-displayedRows {
  margin: 2px !important;
}

/* mui searchbar */
.ForwardRef-searchContainer-8 {
  margin: 2px 8px !important;
  display: flex !important;
  align-items: center !important;
}
.ForwardRef-icon-6 {
  fill: #fff !important;
}
/* .MuiInputBase-input::placeholder{
  color: #f1f1f1 !important;
} */
/* .MuiInputBase-input { */
  /* caret-color: #f1f1f1 !important; */
  /* color: #fff !important; */
/* } */
.ForwardRef-searchIconButton-5 {
  margin-right: -68px !important;
}
.dark .MuiSvgIcon-root {
  fill: rgb(255, 255, 255, 80%) !important;
}
.light .MuiSvgIcon-root {
  fill: rgb(0, 0, 0, 80%) !important;
}

.light .MuiInputBase-input {
  color: rgb(0, 0, 0, 80%) !important;
}

.MuiSwitch-track {
  width: 45px !important;
}

.left-slider {
  height: 30px;
  overflow: hidden;
  position: relative;
}
.left-slider span {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 30px;
  text-align: center;
  /* Starting position */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  /* Apply animation to this element */
  -moz-animation: left-slider 4s ease 1;
  -webkit-animation: left-slider 4s ease 1;
  animation: left-slider 4s ease 1;
}
/* Move it (define the animation) */
@-moz-keyframes left-slider {
  0% {
    -moz-transform: translateX(100%);
  }
  40% {
    -moz-transform: translateX(0%);
  }
  60% {
    -moz-transform: translateX(0%);
  }
  100% {
    -moz-transform: translateX(-100%);
  }
}
@-webkit-keyframes left-slider {
  0% {
    -webkit-transform: translateX(100%);
  }
  40% {
    -webkit-transform: translateX(0%);
  }
  60% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}
@keyframes left-slider {
  0% {
    -moz-transform: translateX(100%); /* Firefox bug fix */
    -webkit-transform: translateX(100%); /* Firefox bug fix */
    transform: translateX(100%);
  }
  40% {
    -moz-transform: translateX(0%); /* Firefox bug fix */
    -webkit-transform: translateX(0%); /* Firefox bug fix */
    transform: translateX(0%);
  }
  60% {
    -moz-transform: translateX(0%); /* Firefox bug fix */
    -webkit-transform: translateX(0%); /* Firefox bug fix */
    transform: translateX(0%);
  }
  100% {
    -moz-transform: translateX(-100%); /* Firefox bug fix */
    -webkit-transform: translateX(-100%); /* Firefox bug fix */
    transform: translateX(-100%);
  }
}

.col1::-webkit-scrollbar,
.col2::-webkit-scrollbar,
.col3::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

.col3 {
  scrollbar-color: rgb(189, 188, 188) transparent !important;
  scrollbar-width: thin !important;
  scrollbar-arrow-color: transparent !important;
}

.col1,
.col2 {
  scrollbar-width: thin !important; /* Firefox */
  scrollbar-color: transparent transparent !important;
}

/* Handle */
.col3::-webkit-scrollbar-thumb {
  background: rgb(189, 188, 188); /*old color : #888*/
  border-radius: 10px;
}

.col1::-webkit-scrollbar-thumb,
.col2::-webkit-scrollbar-thumb {
  background: transparent !important; /*old color : #888*/
  border-radius: 10px;
}

/* scrollbar corner */
.col1::-webkit-scrollbar-corner,
.col2::-webkit-scrollbar-corner,
.col3::-webkit-scrollbar-corner {
  display: none;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: transparent !important;
}

.pieChart .recharts-legend-wrapper {
  max-width: 300px;
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  margin: auto;
  left: 0px;
  right: 0px;
}

.dark .recharts-default-tooltip {
  background-color: transparent !important;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  box-shadow: 1px 1px 10px rgba(15, 0, 68, 0.534);
  border-radius: 5px;
  border: 1px solid rgba(245, 245, 245, 0.178);
}

.light .recharts-default-tooltip {
  background-color: rgba(124, 124, 124, 0.658) !important;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  box-shadow: 1px 1px 10px rgba(15, 0, 68, 0.534);
  border-radius: 5px;
  border: 1px solid rgba(155, 155, 155, 0.3);
}

@media (min-width: 768px) {
  .large .modal-xl {
    width: 90%;
    max-width: 2000px;
  }
}

.modal-header {
  border-bottom: 1px solid #9a9a9a4a;
}

.searchInput:focus {
  outline: 0 !important;
  box-shadow: none!important;
}

.css-1gjgmky-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:last-of-type){
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-color: #4d4d4d;
  border-right: none;
  font-style: normal;
  text-transform: none;
  height: 30px;
}

.css-1gjgmky-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:first-of-type){
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-color: #4d4d4d;
  font-style: normal;
  text-transform: none;
  height: 30px;
}

.pagination a {
  float: left;
  padding: 3px 7px;
  text-decoration: none;
}
.pagination .noActive {
  border: 1px solid #ffff;
  margin-left: 5px;
  margin-right: 5px;
}

.pagination a.active {
  border: 2px solid rgb(68, 135, 235);
  background-color: rgb(68, 135, 235);
}

.pagination .pagination_item_dark {
  color: white !important;
}
.pagination .pagination_item_light {
  color: #303030 !important;
}
.pagination .active_item a {
  color: #fff !important;
}

/* .myDatePicker fieldset.MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}

.myDatePicker .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
  border-color: rgb(119, 118, 118);
} */

legend {
  display:none !important
}

.Dark-Input>input {
  color: #C6C6C6 !important
}

.Light-Input>input {
  color: #303030 !important
}

.ReadOnly-Input>input {
  color: #808080 !important
}

/* .scrollableTable{
  table-layout: fixed; 
  display: block;
  empty-cells: show;
  border-spacing: 0;
  border: 1px solid;
}

.scrollableTable tbody{
  display: block; position:relative;
  width:100%; overflow-y:scroll;
}

.scrollableTable thead{
  position:relative;
  display: block;
  width:100%;
  overflow-y: scroll;
}

.scrollableTable tr{
  width: 100%;
  display:flex;
}

.scrollableTable td,.scrollableTable th{
  flex-basis:100%;
  flex-grow:2;
  display: block;
  padding: 1rem;
  text-align:left;
}

.scrollableTable tbody{
  display: block;
  position: relative;
  width: 100%;
  overflow-y: scroll;
}

.scrollableTbody{
  max-height: 50vh;
} */

.input-adornment,
.input-adornment-detail  {
  position: absolute;
  color: #808080;
  margin: 0;
}

.myTable-scrollbar::-webkit-scrollbar-track-piece:start{
  margin-top: 65px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 576px) {
  .input-adornment,
  .input-adornment-detail {
    top: 32px;
    right: 29px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .input-adornment,
  .input-adornment-detail  {
    top: 32px;
    right: 29px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .input-adornment,
  .input-adornment-detail {
   
    top: 32px;
    right: 29px;
    color: #808080 
  }
} 

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .input-adornment,
  .input-adornment-detail {
    
    top: 32px;
    right: 29px;
    
  }
} 

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .input-adornment,
  .input-adornment-detail {
    top: 32px;
    right: 58px;
  }
}

.fr-box.fr-basic .fr-element {
  color: #000000 !important;
}

.fr-element p {
  margin-block-start: 0em !important;
  margin-block-end: 0em !important;
}

.fr-wrapper div:not([class]) {
  display: none !important;
}


.mde-preview .mde-preview-content table {
  display: table;
}

.mde-preview .mde-preview-content table {
  page-break-inside: auto;
}

.mde-preview .mde-preview-content tbody tr {
  page-break-inside: avoid;
  page-break-before: auto;
}