.detail-table-header-dark {
  font-size: 18px;
  padding: 10px 0px;
  color: #fff;
}

.detail-table-header-white {
  font-size: 18px;
  padding: 10px 0px;
  color: #000;
}

.table-header-cell-dark {
  background-color: #182149;
  color: #fff !important;
}

.table-header-cell-white {
  background-color: #0056D2;
  color: #fff !important;
}

.table-body-cell-dark {
  background-color: "transparent";
  color: "#c6c6c6";
  border-bottom-color: "#5d5d5d";
}

.table-body-cell-white {
  background-color: "transparent";
  color: "#303030";
  border-bottom-color: "grey";
}

legend {
  display:none !important
}

div.ADDED {
  padding-top: 0px;
}

div.ADDED fieldset {
  display: none !important;
}

.cus-class{
  width: 100%;
}

div.cus-class fieldset{
  display: none !important;
}

.quick-table td {
  padding: 5px 10px;
}