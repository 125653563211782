/* print doc */
  
  @page { size: auto;  margin: 5mm; }
  
  @media print {
    .report-cover {
      height: 100vh!important;
      padding: 5vh!important;
      margin-bottom: 0px!important;
      box-shadow: none!important;
    }  
    .report-content {
      height: 100vh!important;
      padding: 15vh 5vh!important;
      margin-bottom: 0px!important;
      box-shadow: none!important;
    }  
  }
